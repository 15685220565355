import React from "react"

export default function errorPage() {
  return (
    <div>
      <div className="content">
        <p>Page not found</p>
      </div>
    </div>
  )
}
